import React, { Component, useState } from "react";
import { Modal, Button } from "react-bootstrap";

import { Link } from "react-router-dom";
import "./Sites.css";
//API handling components
import { BASE_URL } from "./../common/global.js";

import { TextField, FormControl } from "@mui/material";
import Autocomplete from "@mui/lab/Autocomplete";

// react toast
import { toast } from "react-toastify";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// Axios for HTTP req
const axios = require("axios");

export default class Sites extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sites: null,
            id: this.props.id,
            name: this.props.name,
            type: this.props.type,

            newSite: {
                id: null,
                name: null,
                address: null,
                users: null,
                status: null,
            },
            manager: {
                id: null,
                name: null,
                type: null,
            },

            siteManager_id: null,
            siteManager_name: null,

            currentSite_id: null,
            currentSite_name: null,
            currentSite_address: null,
            currentSite_users: null,
            currentManager_id: null,

            updateModal: false,
            viewModal: false,
            ManagersModal: false,
        };
    }

    refresh() {
        window.location.reload(false);
    }

    onChangeSiteAddress(e) {
        let newSite = this.state.newSite;
        newSite.address = e.target.value;
        this.setState({ newSite: newSite });
    }

    onChangeSiteName(e) {
        let newSite = this.state.newSite;
        newSite.name = e.target.value;
        this.setState({ newSite: newSite });
    }

    onChangeSiteEngineers(e) {
        let newSite = this.state.newSite;
        newSite.Engineers = e.target.value;
        this.setState({ newSite: newSite });
    }

    onChangeSiteManager(e) {
        let manager = this.state.manager;
        manager.name = e.target.value;
        this.setState({ manager: manager });
    }

    submitNewSite(e) {
        e.preventDefault();
        let url = BASE_URL;
        const query =
            'INSERT INTO sites (name, address) values("' +
            this.state.newSite.name +
            '", "' +
            this.state.newSite.address +
            '")';

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                toast("new site added!");
                setTimeout(this.refresh, 4000);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    submitSiteUpdate(e) {
        let url = BASE_URL;
        const query =
            'UPDATE sites SET name="' +
            this.state.currentSite_name +
            '", address="' +
            this.state.currentSite_address +
            '" WHERE id=' +
            this.state.currentSite_id +
            "";

        console.log(query);

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                toast("site updated successfully!");
                setTimeout(this.refresh, 4000);
            })
            .catch((error) => {
                console.log(error);
            });

        this.setState({ name: "", email: "", mobileNo: "" });
    }

    submitSiteDelete(id) {
        let url = BASE_URL;
        const query = `UPDATE sites SET status = -1 where id = ${id};`;

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                toast("site deleted successfully!");
                setTimeout(this.refresh, 4000);
            })
            .catch((error) => {
                console.log(error);
            });

        this.setState({ name: "", email: "", mobileNo: "" });
    }

    submitSiteManager(e) {
        let url = BASE_URL;
        const query = `INSERT INTO siteManagers(userId,siteId) values(${this.state.currentManager_id}, ${this.state.currentSite_id})`;

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                toast("new site manager added!");
                setTimeout(this.refresh, 4000);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    fetchUsersData() {
        let url = BASE_URL;
        const query = `SELECT id, name, type FROM users where status = 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                console.log("users data: ", res.data);
                this.setState({ users: res.data });
            })
            .catch((err) => {
                console.log("users data error: ", err);
            });
    }

    fetchSitesData() {
        let url = BASE_URL;
        let query = "";

        if (this.state.type != 1) {
            console.log(this.state.type);
            query = `select * from sites WHERE id in (select siteId from siteManagers WHERE userId = ${this.state.id}) AND status = 1`;
        } else {
            query = `select * from sites WHERE status = 1 `;
        }
        console.log(query);
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                console.log("sites data: ", res.data);
                this.setState({ sites: res.data });
            })
            .catch((err) => {
                console.log("sites data error: ", err);
            });
    }

    fetchSitesManagerData() {
        let url = BASE_URL;
        const query = `select type,s.userId, u.name from siteManagers s join users u on s.userId = u.id where s.siteId = ${this.state.currentSite_id} GROUP BY userId `;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                console.log("manager data: ", res.data);
                this.setState({ managers: res.data, ManagersModal: true });
            })
            .catch((err) => {
                console.log("manager data error: ", err);
            });
    }
    deleteSitesManagerData(userId) {
        let url = BASE_URL;
        const query = `DELETE FROM siteManagers WHERE userId= ${userId}; `;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                toast("manager deleted successfully!");
                setTimeout(this.refresh, 4000);
            })
            .catch((error) => {
                console.log(error);
            });
        this.setState({ id: "", name: "" });
    }

    fetchSitesCount() {
        let url = BASE_URL;
        const query = `SELECT COUNT(id) FROM sites WHERE status = 1;`;
        console.log(query);
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                console.log("count data: ", res.data);
                this.setState({ count: res.data });
            })
            .catch((err) => {
                console.log("counts data error: ", err);
            });
    }

    componentDidMount() {
        this.fetchSitesData();
        this.fetchUsersData();
        this.submitSiteManager();
    }

    componentDidUpdate() {
        $(function () {
            $("#sitesTable")
                .DataTable({
                    destroy: true,
                    responsive: true,
                    lengthChange: false,
                    autoWidth: false,
                    buttons: ["copy", "csv", "excel", "print"],
                })
                .buttons()
                .container()
                .appendTo("#sitesTable_wrapper .col-md-6:eq(0)");
        });
    }

    renderSitesData() {
        const sites = this.state.sites;

        if (sites == null) {
            return null;
        }

        return sites.map((site) => {
            return (
                <tr>
                    <td>{site.id}</td>
                    <td>{site.name}</td>
                    <td>{site.address}</td>
                    <td className = {(this.state.type == 2 ? " d-none" : "")}>
                        <button
                            className="btn btn-sm btn-secondary"
                            onClick={() => {
                                this.setState(
                                    {
                                        currentSite_id: site.id,
                                    },
                                    this.fetchSitesManagerData
                                );
                            }}
                        >
                            view
                        </button>
                    </td>
                    <td>
                        <span class="badge bg-success">
                            {site.status === 1 ? "active" : "inactive"}
                        </span>
                    </td>
                    <td className="">
                        <button
                            class="btn btn-primary btn-sm mx-1"
                            onClick={() => {
                                this.setState({
                                    currentSite_id: site.id,
                                    currentSite_name: site.name,
                                    currentSite_address: site.address,
                                    currentSite_Engineers: site.Engineers,
                                    viewModal: true,
                                });
                            }}
                        >
                            <i class="fas fa-eye"></i>
                        </button>

                        <Link to={`/docsManager/${site.id}`}>
                            <button class="btn btn-primary btn-sm mx-1">
                                <i class="fas fa-paperclip"></i>
                            </button>
                        </Link>
                        <button
                            class="btn btn-primary btn-sm mx-1"
                            onClick={(e) => {
                                this.setState({
                                    currentSite_id: site.id,
                                    currentSite_name: site.name,
                                    currentSite_address: site.address,
                                    currentSite_Engineers: site.Engineers,
                                    updateModal: true,
                                });
                            }}
                        >
                            <i class="fas fa-user-edit"></i>
                        </button>

                        <Link
                            to={`/progress-tracker/${site.id}/${this.state.id}`}
                        >
                            <button
                                class={
                                    "btn btn-primary btn-sm mx-1 " +
                                    (this.state.type == 3 ? "d-none" : "")
                                }
                            >
                                <i class="fas fa-chart-line"></i>
                            </button>
                        </Link>

                        <Link to={`/ledgerManager/${site.id}`}>
                            <button
                                class={
                                    "btn btn-primary btn-sm mx-1 " +
                                    (this.state.type == 2 ? "d-none" : "")
                                }
                            >
                                <i class="fas fa-file-invoice-dollar"></i>
                            </button>
                        </Link>
                        <button
                            class={"btn btn-danger btn-sm mx-1" +
                            (this.state.type == 1 ? " " : " d-none")
                            }
                            onClick={(e) => {
                                if (
                                    window.confirm(
                                        "Really want to delete site?"
                                    )
                                ) {
                                    this.submitSiteDelete(site.id);
                                }
                            }}
                        >
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </tr>
            );
        });
    }

    renderManagersModal() {
        const managers = this.state.managers;

        if (managers == null) {
            return null;
            console.log(null);
        }

        return (
            <Modal
                show={this.state.ManagersModal}
                onHide={() => {
                    this.setState({ ManagersModal: false });
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>View Managers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="mb-5" onSubmit={(e) => e.preventDefault()}>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Add new manager
                            </label>
                            <FormControl
                                style={{ minWidth: "250px" }}
                                className="mr-2 mb-2 smt-0"
                            >
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    options={
                                        this.state.users != null
                                            ? this.state.users.map(
                                                  (item) =>
                                                      item.id + ", " + item.name
                                              )
                                            : []
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            // label="party name"
                                            label="Manager name"
                                            variant="outlined"
                                            size="medium"
                                            value={this.state.siteManager}
                                            onChange={(e) => {
                                                this.onChangeSiteManager(e);
                                            }}
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        console.log(value);
                                        if (value != null && value.length > 2) {
                                            this.setState({
                                                currentManager_id:
                                                    value.split(", ")[0],
                                            });
                                        }
                                    }}
                                />
                            </FormControl>
                            <button
                                class="btn btn-success btn-sm mx-1"
                                onClick={(e) => {
                                    this.submitSiteManager(e);
                                }}
                            >
                                <i class="fa fa-plus" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Current Managers</h3>
                            </div>
                            <div className="card-body p-0">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th style={{ width: 10 }}>#</th>
                                            <th>Name</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {managers.map((managers) => (
                                            <tr>
                                                <td>{managers.userId}</td>
                                                <td>{managers.name}</td>
                                                <td>
                                                    <span class="badge badge-success mx-1">
                                                        active
                                                    </span>
                                                </td>
                                                <td>
                                                    <button
                                                        class="btn btn-danger btn-sm mx-1"
                                                        onClick={(e) => {
                                                            if (
                                                                window.confirm(
                                                                    "Really want to delete site?"
                                                                )
                                                            ) {
                                                                this.deleteSitesManagerData(
                                                                    managers.userId
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.setState({ ManagersModal: false });
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
    renderViewModal() {
        return (
            <Modal
                show={this.state.viewModal}
                onHide={() => {
                    this.setState({ viewModal: false });
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>View Site</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Name:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentSite_name}
                                onChange={(e) => {
                                    this.setState({
                                        currentSite_name: e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteAddress"
                                className="col-form-label"
                            >
                                Address:
                            </label>
                            <textarea
                                className="form-control"
                                name="address"
                                value={this.state.currentSite_address}
                                onChange={(e) => {
                                    this.setState({
                                        currentSite_address: e.target.value,
                                    });
                                }}
                                id="siteAddress"
                                readOnly
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.setState({ viewModal: false });
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    renderUpdateModal() {
        return (
            <Modal
                show={this.state.updateModal}
                onHide={() => {
                    this.setState({ updateModal: false });
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Site</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Name:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentSite_name}
                                onChange={(e) => {
                                    this.setState({
                                        currentSite_name: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteAddress"
                                className="col-form-label"
                            >
                                Address:
                            </label>
                            <textarea
                                className="form-control"
                                name="address"
                                value={this.state.currentSite_address}
                                onChange={(e) => {
                                    this.setState({
                                        currentSite_address: e.target.value,
                                    });
                                }}
                                id="siteAddress"
                                defaultValue={""}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.setState({ updateModal: false });
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={(e) => {
                            this.submitSiteUpdate(e);
                            this.setState({ updateModal: false });
                        }}
                    >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    render() {
        return (
            <div>
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div class="mt-2">
                                        {/* add new site modal */}
                                        <div className="add">
                                            <button
                                                type="button"
                                                class=" new btn btn-primary btn-sm mb-2"
                                                data-toggle="modal"
                                                data-target="#exampleModal"
                                                data-whatever="@mdo"
                                            >
                                                <span>
                                                    <i class="fas fa-building"></i>
                                                </span>
                                                &nbsp;&nbsp;Add New Site
                                            </button>
                                            <div
                                                className="modal fade"
                                                id="exampleModal"
                                                tabIndex={-1}
                                                role="dialog"
                                                aria-labelledby="exampleModalLabel"
                                                aria-hidden="true"
                                            >
                                                <div
                                                    className="modal-dialog"
                                                    role="document"
                                                >
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5
                                                                className="modal-title"
                                                                id="exampleModalLabel"
                                                            >
                                                                Add New Site
                                                            </h5>
                                                            <button
                                                                type="button"
                                                                className="close "
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                            >
                                                                <span aria-hidden="true">
                                                                    ×
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <form>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="siteName"
                                                                        className="col-form-label"
                                                                    >
                                                                        Name:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="siteName"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeSiteName(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="siteAddress"
                                                                        className="col-form-label"
                                                                    >
                                                                        Address:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="address"
                                                                        className="form-control"
                                                                        id="siteEngineers"
                                                                        placeholder="Enter site address here"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeSiteAddress(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            </form>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                data-dismiss="modal"
                                                            >
                                                                Close
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    this.submitNewSite(
                                                                        e
                                                                    );
                                                                }}
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <table
                                            id="sitesTable"
                                            className="table table-bordered table-striped"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>id</th>
                                                    <th>Name</th>
                                                    <th>Address</th>

                                                    <th className = {(this.state.type == 2 ? " d-none" : "")}>Managers</th>
                                                    <th>Status</th>
                                                    <th id="no-print">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderSitesData()}
                                                <div
                                                    className="modal fade"
                                                    id="update"
                                                    tabIndex={-1}
                                                    role="dialog"
                                                    aria-labelledby="exampleModalLabel"
                                                    aria-hidden="true"
                                                >
                                                    <div
                                                        className="modal-dialog"
                                                        role="document"
                                                    >
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5
                                                                    className="modal-title"
                                                                    id="exampleModalLabel"
                                                                >
                                                                    New message
                                                                </h5>
                                                                <button
                                                                    type="button"
                                                                    className="close"
                                                                    data-dismiss="modal"
                                                                    aria-label="Close"
                                                                >
                                                                    <span aria-hidden="true">
                                                                        ×
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <form>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="recipient-name"
                                                                            className="col-form-label"
                                                                        >
                                                                            Recipient:
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="recipient-name"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="message-text"
                                                                            className="col-form-label"
                                                                        >
                                                                            Message:
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            id="message-text"
                                                                            defaultValue={
                                                                                ""
                                                                            }
                                                                        />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary"
                                                                    data-dismiss="modal"
                                                                >
                                                                    Close
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary"
                                                                >
                                                                    update
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                    {this.renderManagersModal()}
                    {this.renderViewModal()}
                    {this.renderUpdateModal()}
                </div>
                {/* /.content-wrapper */}
            </div>
        );
    }
}
