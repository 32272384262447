import React, { Component } from "react";
import RichTextEditor from "react-rte"; // import styles
import Parser from "html-react-parser";

//API handling components
import { BASE_URL } from "./../common/global.js";

// react toast
import { toast } from "react-toastify";

import { AuthContext } from "../../Context/authContext";

// Axios for HTTP req
const axios = require("axios");

export default class ProgressTracker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            siteData: null,
            siteManagers: null,
            messages: null,
            siteId: this.props.match.params.siteId,
            userId: this.props.match.params.userId,
            message: RichTextEditor.createEmptyValue(),
        };
    }

    refresh() {
        window.location.reload(false);
    }

    onChange = (message) => {
        console.log(message.toString("html"));
        this.setState({ message });
    };

    fetchSiteData() {
        let url = BASE_URL;
        const query = `select name from sites where id = ${this.state.siteId};`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                console.log("site data: ", res.data);
                this.setState({ siteData: res.data });
            })
            .catch((err) => {
                console.log("site data error: ", err);
            });
    }
    fetchSiteManagers() {
        let url = BASE_URL;
        const query = `select s.userId, u.name from siteManagers s join users u on s.userId = u.id GROUP BY userId;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                console.log("site manager data: ", res.data);
                this.setState({ siteManagers: res.data });
            })
            .catch((err) => {
                console.log("site manager data error: ", err);
            });
    }
    fetchMessageData() {
        let url = BASE_URL;
        const query = `SELECT p.message, p.lastModified, s.name as siteName, u.name as userName FROM progressTracker p join sites s on s.id = p.siteId join users u on u.id = p.userId where p.siteId = ${this.state.siteId} ORDER BY p.id DESC;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                console.log("msg data: ", res.data);
                this.setState({ messages: res.data });
            })
            .catch((err) => {
                console.log("msg data error: ", err);
            });
    }

    submitMessage() {
        let url = BASE_URL;
        const query =
            'INSERT INTO progressTracker (siteId, userId, message) values("' +
            this.state.siteId +
            '", "' +
            this.state.userId +
            '", "' +
            this.state.message.toString("html") +
            '") ';

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                toast("new message posted!");
                setTimeout(this.refresh, 4000);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    componentDidMount() {
        this.fetchSiteData();
        this.fetchMessageData();
        this.fetchSiteManagers();
    }

    renderSiteData() {
        if (this.state.siteData == null) return null;
        return <h5>{this.state.siteData[0].name}</h5>;
    }
    renderSiteManagers() {
        if (
            this.state.siteManagers == null ||
            this.state.siteManagers.length < 1
        )
            return (
                <span class="badge badge-secondary mx-1">
                    No manager(s) found !
                </span>
            );
        return this.state.siteManagers.map((siteManagers, index) => {
            return (
                <span id={`${index}`} class="badge badge-secondary mx-1">
                    {siteManagers.name}
                </span>
            );
        });
    }

    renderMessages() {
        if (this.state.messages == null) return null;

        return this.state.messages.map((item, index) => {
            // process date
            item.lastModified = item.lastModified
                .replace("T", " ")
                .replace("Z", " ");
            let date = item.lastModified.split(" ")[0];
            let today = new Date().toISOString().slice(0, 10);

            let timeLineColor = date == today ? "text-green" : "";
            return (
                <div id={`${index}`}>
                    <div className="timeline-item">
                        <span className={`time ${timeLineColor}`}>
                            <i className="fas fa-calendar-week">&nbsp;&nbsp;</i>
                            {item.lastModified}
                        </span>
                        <h3 className="timeline-header">
                            <a href="#">{item.userName}</a>
                        </h3>
                        <div className="timeline-body">
                            {Parser(item.message)}
                        </div>
                    </div>
                </div>
            );
        });
    }

    render() {
        return (
            <div>
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    {this.renderSiteData()}
                                </div>
                                <div className="col-sm-6">
                                    <h5>{this.renderSiteManagers()}</h5>
                                </div>
                            </div>
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {/* Main content */}
                    <section className="content">
                        <div className="row">
                            <div className="col-md-4 pull-md-right">
                                <RichTextEditor
                                    value={this.state.message}
                                    onChange={this.onChange}
                                />
                                <button
                                    className="btn-primary btn-sm px-5 mt-1 mb-4"
                                    onClick={() => {
                                        this.submitMessage();
                                    }}
                                >
                                    SEND
                                </button>
                            </div>
                            <div className="col-md-8">
                                <div className="timeline">
                                    {this.renderMessages()}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
            </div>
        );
    }
}
