import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
//import TextField from '@material-ui/core/TextField';

//API handling components
import { BASE_URL } from "./../common/global.js";
import TextField from "@mui/material/TextField";

// react toast
import { toast } from "react-toastify";
import { AuthContext } from "../../Context/authContext";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// Axios for HTTP req
const axios = require("axios");

export default class MachineDocsManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            siteId: this.props.match.params.siteId,
            siteName: "",
            userId: 1,
            doc: null,
            id: null,
            name: "",
            selectedFile: null,
            activeDocPath: null,
            currentDoc_siteid: null,
            currentDoc_userId: null,
            currentDoc_dateTime: null,
            currentDoc_credit: null,

            updateModal: false,
            viewModal: false,
        };
    }

    refresh() {
        window.location.reload(false);
    }

    fetchSiteName() {
        let url = BASE_URL;
        const query = `SELECT name FROM machines where id = ${this.state.siteId};`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                this.setState({ siteName: res.data[0]["name"] });
            })
            .catch((err) => {
                console.log("site data error: ", err);
            });
    }

    fetchDocsData() {
        let url = BASE_URL;
        const query = `SELECT * FROM machineDocuments where status = 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                // console.log("docs data: ", res.data);
                this.setState({ doc: res.data });

                // fetch site name
                this.fetchSiteName();
            })
            .catch((err) => {
                console.log("docs data error: ", err);
            });
    }

    onChangeDocId(e) {
        let newDoc = this.state.newDoc;
        newDoc.siteId = e.target.value;
        this.setState({ newDoc: newDoc });
    }

    onChangeDocUserId(e) {
        let newDoc = this.state.newDoc;
        newDoc.userId = e.target.value;
        this.setState({ newDoc: newDoc });
    }

    onChangeDocCredit(e) {
        let newDoc = this.state.newDoc;
        newDoc.credit = e.target.value;
        this.setState({ newDoc: newDoc });
    }

    onChangeDocName(e) {
        let docsName = this.state.docsName;
        docsName.name = e.target.value;
        this.setState({ docsName: docsName });
    }

    handleInputChange = (event) => {
        this.setState({
            selectedFile: event.target.files[0],
        });
    };

    submitFile() {
        const data = new FormData();
        data.append("fileToUpload", this.state.selectedFile);
        let url = "http://umika.5techg.com/api/fileUpload/";

        axios.post(url, data).then((res) => {
            // then print response status
            this.setState({ activeDocPath: res.data }, this.handleSubmit);
        });
    }

    handleSubmit() {
        const { name, activeDocPath } = this.state;
        const query = `INSERT INTO machineDocuments (name, path) VALUES('${name}',"${activeDocPath}")`;
        console.log(query);
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(BASE_URL, data)
            .then((res) => {
                toast("machine Documents saved successfully");
                setTimeout(this.refresh, 3000);
            })
            .catch((err) => {
                console.log(err);
                toast("failed to save");
            });
    }

    submitDocsDelete(id) {
        let url = BASE_URL;
        const query = `UPDATE machineDocuments SET status = -1 where id = ${id};`;

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                toast("machine Document deleted successfully!");
                setTimeout(this.refresh, 4000);
            })
            .catch((error) => {
                console.log(error);
            });

        this.setState({ name: "", email: "", mobileNo: "" });
    }

    componentDidMount() {
        this.fetchDocsData();
    }

    componentDidUpdate() {
        $(function () {
            $("#sitesTable")
                .DataTable({
                    destroy: true,
                    responsive: true,
                    lengthChange: false,
                    autoWidth: false,
                    buttons: ["copy", "csv", "excel", "pdf", "print"],
                })
                .buttons()
                .container()
                .appendTo("#sitesTable_wrapper .col-md-6:eq(0)");
        });
    }

    renderDocsData() {
        if (!this.state.doc) {
            return null;
        }

        return this.state.doc.map((site, index) => {
            return (
                <tr>
                    <td>{site.id}</td>
                    <td>{site.name}</td>
                    <td>
                        <a
                            href={site.path}
                            target="_blank"
                            class="btn btn-primary btn-sm mx-1"
                        >
                            <i class="fas fa-eye"></i>
                        </a>
                    </td>
                    <td className="">
                        <button
                            class="btn btn-danger btn-sm mx-1 "
                            onClick={(e) => {
                                if (
                                    window.confirm(
                                        "Really want to delete document?"
                                    )
                                ) {
                                    this.submitDocsDelete(site.id);
                                }
                            }}
                        >
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </tr>
            );
        });
    }

    render() {
        return (
            <div>
                {/* add description debit credit for user starts here */}

                {/* add description debit credit for user ends here */}
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-2 mb-3 mt-3 ">
                                            <h4>{this.state.siteName}</h4>
                                        </div>
                                        <div className="col-10">
                                            <div class="input-group mb-3 mt-3 info-add">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div>
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                className="form-control mt-1"
                                                                id="siteName"
                                                                value={
                                                                    this.state
                                                                        .name
                                                                }
                                                                placeholder="Enter Name"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    this.setState(
                                                                        {
                                                                            name:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                        <TextField
                                                            name="fileToUpload"
                                                            size="small"
                                                            type="file"
                                                            variant="outlined"
                                                            className="mr-3 ml-3 mt-1 inputField"
                                                            onChange={this.handleInputChange}
                                                        />
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary ml-1 mt-1"
                                                            onClick={(e) => {
                                                                this.submitFile(
                                                                    e
                                                                );
                                                            }}
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <table
                                        id="sitesTable"
                                        className="table table-bordered table-striped mt-2"
                                    >
                                        <thead>
                                            <tr>
                                                <th>Id</th>

                                                <th>Name</th>
                                                <th>View</th>
                                                <th>Option</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderDocsData()}
                                            <div
                                                className="modal fade"
                                                id="update"
                                                tabIndex={-1}
                                                role="dialog"
                                                aria-labelledby="exampleModalLabel"
                                                aria-hidden="true"
                                            >
                                                <div
                                                    className="modal-dialog"
                                                    role="document"
                                                >
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5
                                                                className="modal-title"
                                                                id="exampleModalLabel"
                                                            >
                                                                New message
                                                            </h5>
                                                            <button
                                                                type="button"
                                                                className="close"
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                            >
                                                                <span aria-hidden="true">
                                                                    ×
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <form>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Recipient:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="message-text"
                                                                        className="col-form-label"
                                                                    >
                                                                        Message:
                                                                    </label>
                                                                    <textarea
                                                                        className="form-control"
                                                                        id="message-text"
                                                                        defaultValue={
                                                                            ""
                                                                        }
                                                                    />
                                                                </div>
                                                            </form>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                data-dismiss="modal"
                                                            >
                                                                Close
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                            >
                                                                update
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </tbody>
                                    </table>
                                </div>
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
            </div>
        );
    }
}
