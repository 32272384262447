import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import "./style.css";
//API handling components
import { BASE_URL } from "./../common/global.js";

// react toast
import { toast } from "react-toastify";

import { AuthContext } from "../../Context/authContext";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// Axios for HTTP req
const axios = require("axios");

export default class MachineLedgerManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            finance: null,

            siteId: this.props.match.params.siteId,
            siteName: "N/A",
            userId: 1,

            newFinance: {
                dateTime: null,
                credit: 0,
                debit: 0,
                description: null,
                status: null,
                lastModified: null,
            },

            currentFinance_dateTime: null,
            currentFinance_credit: null,
            currentFinance_debit: null,
            currentFinance_description: null,
            currentFinance_status: null,
            currentFinance_lastModified: null,

            updateModal: false,
            viewModal: false,
        };
    }

    refresh() {
        window.location.reload(false);
    }

    fetchSiteName() {
        let url = BASE_URL;
        const query = `SELECT name FROM machines where id = ${this.state.siteId} and status = 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                console.log("site name: ", res.data);
                this.setState({ siteName: res.data[0]["name"] });
            })
            .catch((err) => {
                console.log("finance data error: ", err);
            });
    }

    fetchFinanceData() {
        let url = BASE_URL;
        const query = `SELECT * FROM machineFinance where machineId = ${this.state.siteId} and status = 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                console.log("finance data: ", res.data);
                this.setState({ finance: res.data });

                // fetch site name
                this.fetchSiteName();
            })
            .catch((err) => {
                console.log("finance data error: ", err);
            });
    }

    onChangeFinanceDateTime(e) {
        let newFinance = this.state.newFinance;
        newFinance.dateTime = e.target.value;
        this.setState({ newFinance: newFinance });
    }

    onChangeFinanceCredit(e) {
        let newFinance = this.state.newFinance;
        newFinance.credit = e.target.value;
        this.setState({ newFinance: newFinance });
    }

    onChangeFinanceDebit(e) {
        let newFinance = this.state.newFinance;
        newFinance.debit = e.target.value;
        this.setState({ newFinance: newFinance });
    }

    onChangeFinanceDescription(e) {
        let newFinance = this.state.newFinance;
        newFinance.description = e.target.value;
        this.setState({ newFinance: newFinance });
    }

    submitNewFinance(e) {
        const url = BASE_URL;
        const query = `INSERT INTO machineFinance (machineId, userId, credit, debit, description) values(${this.state.siteId}, ${this.state.userId}, ${this.state.newFinance.credit}, ${this.state.newFinance.debit}, "${this.state.newFinance.description}")`;
        console.log(query);
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                toast("new record added!");
                setTimeout(this.refresh, 4000);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    submitSiteDelete(id) {
        let url = BASE_URL;
        const query = `UPDATE finance SET status = -1 where id = ${id};`;

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                toast("record deleted successfully!");
                setTimeout(this.refresh, 4000);
            })
            .catch((error) => {
                console.log(error);
            });

        this.setState({ name: "", email: "", mobileNo: "" });
    }

    componentDidMount() {
        this.fetchFinanceData();
    }

    componentDidUpdate() {
        $(function () {
            $("#ledgerTable")
                .DataTable({
                    destroy: true,
                    responsive: true,
                    lengthChange: false,
                    autoWidth: false,
                    buttons: ["copy", "csv", "excel", "pdf", "print"],
                })
                .buttons()
                .container()
                .appendTo("#ledgerTable_wrapper .col-md-6:eq(0)");
        });
    }

    renderFinanceData() {
        const finance = this.state.finance;
        if (finance == null) {
            return null;
        }

        return finance.map((site) => {
            return (
                <tr>
                    <td>{site.id}</td>
                    <td>
                        {
                            site.dateTime
                                .replace("T", " ")
                                .replace("Z", " ")
                                .split(".")[0]
                        }
                    </td>
                    <td>{site.credit}</td>
                    <td>{site.debit}</td>

                    <td>{site.description}</td>

                    <td>
                        <span class="badge bg-success">
                            {site.status === 1 ? "valid" : "deleted"}
                        </span>
                    </td>
                    <td className="">
                        <button
                            class="btn btn-danger btn-sm mx-1"
                            onClick={(e) => {
                                if (
                                    window.confirm(
                                        "Really want to delete site?"
                                    )
                                ) {
                                    this.submitSiteDelete(site.id);
                                }
                            }}
                        >
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </tr>
            );
        });
    }

    render() {
        return (
            <div>
                {/* add description debit credit for user starts here */}

                {/* add description debit credit for user ends here */}
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 mt-2">
                                    <h4>{this.state.siteName}</h4>
                                    {/* add new site modal */}

                                    <div class="input-group mb-3 mt-3 info-add">
                                    <div class="form-group col-xs-3 col-md-3">
                                        <label
                                            htmlFor="siteName"
                                            className="col-form-label"
                                        >
                                            Debit:
                                        </label>
                                        <input
                                            type="number"
                                            name="debit"
                                            className="form-control "
                                            id="financeDebit"
                                            placeholder="debit"
                                            value={this.state.newFinance.debit}
                                            onChange={(e) => {
                                                this.onChangeFinanceDebit(e);
                                            }}
                                        />
                                        </div>
                                        <div class="form-group col-xs-3 col-md-3">
                                        <label
                                            htmlFor="siteName"
                                            className="col-form-label"
                                        >
                                            Credit:
                                        </label>
                                        <input
                                            type="number"
                                            name="credit"
                                            className="form-control "
                                            id="financeCredit"
                                            placeholder="credit"
                                            value={this.state.newFinance.credit}
                                            onChange={(e) => {
                                                this.onChangeFinanceCredit(e);
                                            }}
                                        />
                                        </div>
                                        <div class="form-group col-xs-3 col-md-3">
                                        <label
                                            htmlFor="siteName"
                                            className="col-form-label"
                                        >
                                            Description:
                                        </label>
                                        <input
                                            type="text"
                                            name="description"
                                            className="form-control "
                                            id="financeDescription"
                                            placeholder="description"
                                            onChange={(e) => {
                                                this.onChangeFinanceDescription(
                                                    e
                                                );
                                            }}
                                        />
                                        </div>
                                        <div class="form-group col-xs-3 col-md-3">
                                        <div className="form-group plus mt-5">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={(e) => {
                                                    this.submitNewFinance(e);
                                                }}
                                                style={{marginTop:'-.4em'}}
                                            >
                                                <i class="fas fa-plus-square"></i>
                                            </button>
                                        </div>
                                        </div>
                                    </div>

                                    <table
                                        id="ledgerTable"
                                        className="table table-bordered table-striped"
                                    >
                                        <thead>
                                            <tr>
                                                <th>Id</th>

                                                <th>Date & Time</th>
                                                <th>credit</th>
                                                <th>debit</th>
                                                <th>description</th>

                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderFinanceData()}
                                            <div
                                                className="modal fade"
                                                id="update"
                                                tabIndex={-1}
                                                role="dialog"
                                                aria-labelledby="exampleModalLabel"
                                                aria-hidden="true"
                                            >
                                                <div
                                                    className="modal-dialog"
                                                    role="document"
                                                >
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5
                                                                className="modal-title"
                                                                id="exampleModalLabel"
                                                            >
                                                                New message
                                                            </h5>
                                                            <button
                                                                type="button"
                                                                className="close"
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                            >
                                                                <span aria-hidden="true">
                                                                    ×
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <form>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Recipient:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="message-text"
                                                                        className="col-form-label"
                                                                    >
                                                                        Message:
                                                                    </label>
                                                                    <textarea
                                                                        className="form-control"
                                                                        id="message-text"
                                                                        defaultValue={
                                                                            ""
                                                                        }
                                                                    />
                                                                </div>
                                                            </form>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                data-dismiss="modal"
                                                            >
                                                                Close
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                            >
                                                                update
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </tbody>
                                    </table>
                                </div>
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
            </div>
        );
    }
}
