import React, { useContex,useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { useHistory } from 'react-router-dom';
// react toast
import { toast } from "react-toastify";


function Menu(props)  {
    const history = useHistory();
    
    const [name, setName] = useState(
        localStorage.getItem("name") != "null"
            ? String(localStorage.getItem("name"))
            : false
    );
    console.log(name)

    const [type, setType] = useState(
        localStorage.getItem("type") != "null"
            ? String(localStorage.getItem("type"))
            : false
    );
    console.log(type)

    const [id, setId] = useState(
        localStorage.getItem("id") != "null"
            ? String(localStorage.getItem("id"))
            : false
    );
            console.log(id)

        // var name = localStorage.getItem('_name');
        // console.log(name)
        // const type = localStorage.getItem("_type");;
        // const id = localStorage.getItem("_id");;
        
     

        const logout = () => {
          
            history.push('/');
            toast("Logout succesufull.");
            window.localStorage.clear();
            console.clear()

         }

        return (
            <div>
                {/* Main Sidebar Container */}
                <aside className="main-sidebar sidebar-dark-primary elevation-4">
                    {/* Brand Logo */}
                    <div className="brand-link">
                        <Link to={`/dashboard`}>
                            <img
                                src="dist/img/AdminLTELogo.png"
                                alt="AdminLTE Logo"
                                className="brand-image img-circle elevation-3"
                                style={{ opacity: ".8" }}
                            />
                            <span className="brand-text font-weight-light">
                                <b>Umika Associates</b>
                            </span>
                        </Link>
                    </div>
                    {/* Sidebar */}
                    <div className="sidebar">
                        {/* Sidebar user panel (optional) */}
                        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                            <div className="image mt-1">
                                <img
                                    src="dist/img/user2-160x160.jpg"
                                    className="img-circle elevation-2"
                                    alt="User Image"
                                />
                            </div>
                            <div className="info" className="d-flex">
                                <a className="d-flex d-auto mx-2" style={{ whiteSpace: 'break-spaces'}}>
                                {name}
                                </a>
                                <div class="clearfix"></div>

                                <Button
                                    variant="outline-secondary"
                                    onClick={logout}
                                    size="sm"
                                >
                                    <i class="fas fa-sign-out-alt m-0"></i>
                                </Button>
                            </div>
                        </div>

                        {/* Sidebar Menu */}
                        <nav className="mt-2">
                            <ul
                                className="nav nav-pills nav-sidebar flex-column nav-treeview"
                                data-widget="treeview"
                                role="menu"
                                data-accordion="false"
                            >
                                {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
                                <li className={"nav-item "  + (type == 1 ? "d-block": "d-none")}>
                                    <Link
                                        to="/dashboard"
                                        className="nav-link active" 
                                    >
                                        <p>Dashboard</p>
                                    </Link>
                                </li>
                                <li className={"nav-item "  + (type == 1,2,3,4 ? "d-block": "d-none")}>
                                    <Link
                                        to="/sites"
                                        className="nav-link active"
                                    >
                                        <p>Sites</p>
                                    </Link>
                                </li>
                                <li className={"nav-item menu-open "  + (type == 1 ? "d-block": "d-none")}>
                                    <Link
                                        to="/machines"
                                        className="nav-link active"
                                    >
                                        <p>Machines</p>
                                    </Link>
                                </li>

                                <li className={"nav-item menu-open "  + (type == 1 ? "d-block": "d-none")}>
                                    <Link
                                        to="/users"
                                        className="nav-link active"
                                    >
                                        <p>Users</p>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                        {/* /.sidebar-menu */}
                    </div>
                    {/* /.sidebar */}
                </aside>
            </div>
        );
  
}

export default Menu;