import React, { useState } from "react";
import "./Login.css";
import { Form, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { Card } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import LinearProgress from "@material-ui/core/LinearProgress";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../common/global";
import { useHistory } from 'react-router-dom';
import umika from "../../logo/umika.png";

// import {AuthContext} from '../../Context/authContext';



const axios = require("axios");


const LoginForm = () => {
    const history = useHistory();

    //  const [_name,_type,_id] = useContext(AuthContext);
    //  const [name, setName] = _name;
    //  const [type, setType] = _type;
    //  const [id, setId] = _id;


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const Authenticate = (_password, _id, _name, _type) => {
        if (_password == password) {
            // setId(_id);
            // setName(_name);
            // setType(_type);


            localStorage.setItem("id", _id);
            parseInt(localStorage.id);
            localStorage.setItem("name", _name);
            localStorage.setItem("type", _type);
            parseInt(localStorage.type);


            if(_type == 1){
                toast("Login Succesfull");
                history.push("/dashboard");
            }
            else {
                toast("Login Succesfull");
                history.push("/sites");
            }

        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        let url = BASE_URL;

        //production
        const query = `SELECT id,name,password,type from users where email like '${email}';`;

        //dev
        //const query = `SELECT * from users where name like 'admin' AND password like 'admin@123';`;

        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                if (res.data.length > 0) {
                    Authenticate(res.data[0].password, res.data[0].id, res.data[0].name, res.data[0].type);
                    window.location.reload(false);

                } else {
                    toast("Incorrect email and password");
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.log("HTTP request error: ", err);
            });

    };



    return (

        <div>

            <div className="hold-transition login-page">
                <div className="login-box">
                    <div className="login-logo">
                        <a href="../../index2.html">
                            <img
                                src={umika}
                                alt="logo"
                                className="img-fluid"
                                style={{height:'150px',width:'360px'}}
                            />
                        </a>
                    </div>
                    {/* /.login-logo */}
                    <div className="card">
                        <div className="card-body login-card-body">
                            <form
                                onSubmit={handleSubmit}
                                action="/dashboard"
                                method="post"
                                className="mt-5"
                            >
                                <Form.Group controlId="formBasicEmail">
                                    <div className="input-group  mt-3">
                                        <Form.Control
                                            type="email"
                                            className="form-control"
                                            placeholder="Email"
                                            name="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-user" />
                                            </div>
                                        </div>
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <div className="input-group mb-3 mt-3">
                                        <Form.Control
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            placeholder="Password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-lock" />
                                            </div>
                                        </div>
                                    </div>
                                </Form.Group>
                                <div className="row">
                                    {/* /.col */}
                                    <div className="col-12 signin">
                                        <button

                                            class="btn btn-primary sign"
                                            onClick={(e) => handleSubmit(e)}
                                        >
                                            Sign In
                                        </button>
                                    </div>

                                    {/* /.col */}
                                    <p class="col-12 mb-1 ">
                                        <a
                                            href="forgot-password.html"
                                            className="forgot mx-auto my-3"
                                        >
                                            I forgot my password
                                        </a>
                                    </p>
                                </div>
                            </form>

                            {/* /.social-auth-links */}
                        </div>
                        {/* /.login-card-body */}
                    </div>
                </div>
            </div>
            <ToastContainer
                position={toast.POSITION.TOP_RIGHT}
                autoClose={4000}
            />
        </div>
    );
};

export default LoginForm;
