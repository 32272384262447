import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import "./style.css";
//API handling components
import { BASE_URL } from "../common/global.js";
import { Link } from "react-router-dom";

// react toast
import { toast } from "react-toastify";
import { AuthContext } from "../../Context/authContext";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// Axios for HTTP req
const axios = require("axios");

export default class MachinesManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            machines: null,

            newFinance: {
                machineId: null,
                userId: null,
                dateTime: null,
                credit: null,
                debit: null,
                description: null,
                status: null,
                lastModified: null,
            },

            newMachine: {
                id: null,
                name: null,
            },

            currentFinance_machineid: null,
            currentFinance_userId: null,
            currentFinance_dateTime: null,
            currentFinance_credit: null,
            currentFinance_debit: null,
            currentFinance_description: null,
            currentFinance_status: null,
            currentFinance_lastModified: null,

            updateModal: false,
            viewModal: false,
        };
    }

    refresh() {
        window.location.reload(false);
    }

    onChangeFinanceId(e) {
        let newFinance = this.state.newFinance;
        newFinance.machineId = e.target.value;
        this.setState({ newFinance: newFinance });
    }

    onChangeFinanceUserId(e) {
        let newFinance = this.state.newFinance;
        newFinance.userId = e.target.value;
        this.setState({ newFinance: newFinance });
    }

    onChangeFinanceDateTime(e) {
        let newFinance = this.state.newFinance;
        newFinance.dateTime = e.target.value;
        this.setState({ newFinance: newFinance });
    }

    onChangeFinanceCredit(e) {
        let newFinance = this.state.newFinance;
        newFinance.credit = e.target.value;
        this.setState({ newFinance: newFinance });
    }

    onChangeFinanceDebit(e) {
        let newFinance = this.state.newFinance;
        newFinance.debit = e.target.value;
        this.setState({ newFinance: newFinance });
    }

    onChangeFinanceDescription(e) {
        let newFinance = this.state.newFinance;
        newFinance.description = e.target.value;
        this.setState({ newFinance: newFinance });
    }

    onChangeMachineId(e) {
        let newMachine = this.state.newMachine;
        newMachine.id = e.target.value;
        this.setState({ newMachine: newMachine });
    }

    onChangeMachineName(e) {
        let newMachine = this.state.newMachine;
        newMachine.name = e.target.value;
        this.setState({ newMachine: newMachine });
    }

    // onChangeMachineAddress(e) {
    //     let newMachine = this.state.newMachine;
    //     newMachine.address = e.target.value;
    //     this.setState({ newMachine: newMachine });
    // }

    submitNewMachine(e) {
        const url = BASE_URL;
        const query =
            'INSERT INTO machines (name) values("' +
            this.state.newMachine.name +
            '")';

        console.log(query);

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                toast("new record added!");
                setTimeout(this.refresh, 4000);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    submitNewFinance(e) {
        const url = BASE_URL;
        const query = `INSERT INTO machines (name) values(${this.state.newMachine.name})`;

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                toast("new machine added!");
                setTimeout(this.refresh, 4000);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    submitSiteDelete(id) {
        let url = BASE_URL;
        const query = `UPDATE machines SET status = -1 where id = ${id};`;

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                toast("machine deleted successfully!");
                setTimeout(this.refresh, 4000);
            })
            .catch((error) => {
                console.log(error);
            });

        this.setState({ name: "", email: "", mobileNo: "" });
    }

    fetchMachineData() {
        let url = BASE_URL;
        const query = `SELECT * FROM machines where status = 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                console.log("machines data: ", res.data);
                this.setState({ machines: res.data });
            })
            .catch((err) => {
                console.log("machines data error: ", err);
            });
    }

    componentDidMount() {
        this.fetchMachineData();
    }

    componentDidUpdate() {
        $(function () {
            $("#machinesTable")
                .DataTable({
                    destroy: true,
                    responsive: true,
                    lengthChange: false,
                    autoWidth: false,
                    buttons: ["copy", "csv", "excel", "print"],
                })
                .buttons()
                .container()
                .appendTo("#machinesTable_wrapper .col-md-6:eq(0)");
        });
    }

    renderMachinesData() {
        const machines = this.state.machines;
        if (machines == null) {
            return null;
        }

        return machines.map((machine) => {
            // process date

            machine.lastModified = machine.lastModified
                .replace("T", " ")
                .replace("Z", " ")
                .replace(".", " ")
                .replace("000", " ");

            return (
                <tr>
                    <td>{machine.id}</td>
                    <td>{machine.name}</td>

                    <td>{machine.lastModified}</td>
                    <td>
                        <span class="badge bg-success">
                            {machine.status === 1 ? "active" : "inactive"}
                        </span>
                    </td>
                    <td className="">
                        <Link to={`/machineDocsManager/${machine.id}`}>
                            <button class="btn btn-primary btn-sm mx-1">
                                <i class="fas fa-paperclip"></i>
                            </button>
                        </Link>

                        <Link to={`/machineLedgerManager/${machine.id}`}>
                            <button class="btn btn-primary btn-sm mx-1">
                                <i class="fas fa-file-invoice-dollar"></i>
                            </button>
                        </Link>
                        <button
                            class="btn btn-danger btn-sm mx-1"
                            onClick={(e) => {
                                if (
                                    window.confirm(
                                        "Really want to delete machine?"
                                    )
                                ) {
                                    this.submitSiteDelete(machine.id);
                                }
                            }}
                        >
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </tr>
            );
        });
    }

    render() {
        return (
            <div>
                {/* add description debit credit for user starts here */}

                {/* add description debit credit for user ends here */}
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div class="mt-2">
                                        {/* add new machine modal */}
                                        <div className="add">
                                            <button
                                                type="button"
                                                class=" new btn btn-primary btn-sm mb-2"
                                                data-toggle="modal"
                                                data-target="#exampleModal"
                                                data-whatever="@mdo"
                                            >
                                                <span>
                                                    <i class="fas fa-cogs"></i>
                                                </span>
                                                &nbsp;&nbsp;Add New Machine
                                            </button>
                                            <div
                                                className="modal fade"
                                                id="exampleModal"
                                                tabIndex={-1}
                                                role="dialog"
                                                aria-labelledby="exampleModalLabel"
                                                aria-hidden="true"
                                            >
                                                <div
                                                    className="modal-dialog"
                                                    role="document"
                                                >
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5
                                                                className="modal-title"
                                                                id="exampleModalLabel"
                                                            >
                                                                Add New Machine
                                                            </h5>
                                                            <button
                                                                type="button"
                                                                className="close "
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                            >
                                                                <span aria-hidden="true">
                                                                    ×
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <form>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="machineName"
                                                                        className="col-form-label"
                                                                    >
                                                                        Name:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="machineName"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeMachineName(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                                {/* <div className="form-group">
                                                                    <label
                                                                        htmlFor="machineAddress"
                                                                        className="col-form-label"
                                                                    >
                                                                        Address:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="address"
                                                                        className="form-control"
                                                                        id="machineEngineers"
                                                                        placeholder="Enter machine address here"
                                                                        onChange={(e) => {
                                                                            this.onChangeMachineAddress(e);
                                                                        }}
                                                                    />
                                                                </div> */}
                                                            </form>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                data-dismiss="modal"
                                                            >
                                                                Close
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    this.submitNewMachine(
                                                                        e
                                                                    );
                                                                }}
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <table
                                            id="machinesTable"
                                            className="table table-bordered table-striped"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>Machine Id</th>

                                                    <th>Machine Name</th>

                                                    <th>lastModified</th>

                                                    <th>Status</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderMachinesData()}
                                                <div
                                                    className="modal fade"
                                                    id="update"
                                                    tabIndex={-1}
                                                    role="dialog"
                                                    aria-labelledby="exampleModalLabel"
                                                    aria-hidden="true"
                                                >
                                                    <div
                                                        className="modal-dialog"
                                                        role="document"
                                                    >
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5
                                                                    className="modal-title"
                                                                    id="exampleModalLabel"
                                                                >
                                                                    New message
                                                                </h5>
                                                                <button
                                                                    type="button"
                                                                    className="close"
                                                                    data-dismiss="modal"
                                                                    aria-label="Close"
                                                                >
                                                                    <span aria-hidden="true">
                                                                        ×
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <form>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="recipient-name"
                                                                            className="col-form-label"
                                                                        >
                                                                            Recipient:
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="recipient-name"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="message-text"
                                                                            className="col-form-label"
                                                                        >
                                                                            Message:
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            id="message-text"
                                                                            defaultValue={
                                                                                ""
                                                                            }
                                                                        />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary"
                                                                    data-dismiss="modal"
                                                                >
                                                                    Close
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary"
                                                                >
                                                                    update
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
            </div>
        );
    }
}
