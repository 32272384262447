import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./components/navbar/Navbar";
import Dashboard from "./components/dashboard/Dashboard";
import LedgerManager from "./components/ledgermanager/LedgerManager";
import MachineLedgerManager from "./components/ledgermanager/MachineLedgerManager";
import Users from "./components/userManager/Users";
import Sites from "./components/siteManager/Sites";
import DocsManager from "./components/documentManager/DocsManager";
import MachineDocsManager from "./components/documentManager/MachineDocsManager";
import ProgressTracker from "./components/progressTracker/ProgressTracker";
import MachinesManager from "./components/machines/machinesManager";

import LoginForm from "./components/login/Login";
import { Route, Switch, HashRouter } from "react-router-dom";

// import context
// import {AuthContext} from './Context/authContext';

function App() {
    const [name, setName] = useState(
        localStorage.getItem("name") != "null"
            ? String(localStorage.getItem("name"))
            : false
    );

    const [type, setType] = useState(
        localStorage.getItem("type") != "null"
            ? parseInt(localStorage.getItem("type"))
            : false
    );
    const [id, setId] = useState(
        localStorage.getItem("id") != "null"
            ? parseInt(localStorage.getItem("id"))
            : 0
    );

    // const login = details => {
    //     console.log(details);
    // }
    // const [name, setName] = useState(
    //     localStorage.getItem("_name") != "null"
    //         ? String(localStorage.getItem("name"))
    //         : false
    // );
    // const [type, setType] = useState(
    //     localStorage.getItem("_type") != "0"
    //         ? Number(localStorage.getItem("_type"))
    //         : false
    // );
    // const [id, setId] = useState(false);

    return (
        <div className="wrapper">
            <HashRouter>
                <Switch>
                    <Route exact path="/" component={LoginForm} />
                    <div>
                        <Navbar />
                        <Route path="/dashboard" component={Dashboard} />
                        <Route path="/users" component={Users} />
                        <Route path="/sites">
                            <Sites id={id} name={name} type={type} />
                        </Route>
                        <Route
                            path="/progress-tracker/:siteId/:userId"
                            component={ProgressTracker}
                        />
                        <Route
                            path="/ledgerManager/:siteId"
                            component={LedgerManager}
                        />
                        <Route
                            path="/machineLedgerManager/:siteId"
                            component={MachineLedgerManager}
                        />
                        <Route
                            path="/docsManager/:siteId"
                            component={DocsManager}
                        />

                        <Route
                            path="/machineDocsManager/:siteId"
                            component={MachineDocsManager}
                        />
                        <Route path="/machines/" component={MachinesManager} />
                        <Route
                            path="/machines/progress-tracker/:machineId/:userId"
                            component={ProgressTracker}
                        />
                        <Route
                            path="/machines//ledgerManager/:machineId"
                            component={LedgerManager}
                        />
                    </div>
                </Switch>
            </HashRouter>
            <ToastContainer />
        </div>
    );
}

export default App;
